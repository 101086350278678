import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ProductsCategories from "./../component/ProductCategories";
import Fancybox from "../fancybox.js";
import parse from 'html-react-parser';
import {Helmet} from 'react-helmet-async';
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'

const Product = () => {
  const { productSlug } = useParams();
  const [homeContent, setHomeContent] = useState(null);
  const {content, language} = useApp();
  const navigate = useNavigate();

  useEffect(() => {


    window.scrollTo(0,0)
    const getHomeContent = async () => {
      let headersList = {
        Accept: "*/*",
      };

      let response = await fetch(
        `https://kiosks.ee/wp/wp-json/io/v1/product/${productSlug}?lang=${language}`,
        {
          method: "GET",
          headers: headersList,
        }
      );

      let data = await response.json();

      if(!data){
        navigate("/");
      }else{
        initLightboxJS("e5c6-46a0-4cd0-ac2b", "individual");
      }

      data.cssSLUG = "BG"+data.cat.split('-')[0];
 
      setHomeContent(data);
    };
    getHomeContent();
  }, [productSlug, language, navigate]);

  let cssSLUG = homeContent?.cat.substring(0, homeContent?.cat.indexOf('-'));

  return (
    <>
    <Helmet>
      <title>{ `${ homeContent?.title } | Kiosks.ee` }</title>
    </Helmet>
    <div className=" w-full">
      <ProductsCategories></ProductsCategories>
      <div className={` w-full rounded_px mt-4 bg-BG${cssSLUG}`}>
        <div className=" md:grid grid-cols-1 lg:grid-cols-2 gap-20 items-center">
          <div className=" pl-4 pt-4 lg:pl-32 lg:pt-0">
            <Link to={`/products/${homeContent?.cat}`} className=" cursor-pointer flex items-center hover:underline"> 
              <svg width="16" height="16" viewBox="0 0 16 16" className="mr-2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.316 3.47244C6.316 3.47244 3.31533 6.47578 2.14667 7.64511C2.04867 7.74244 2 7.87044 2 7.99844C2 8.12644 2.04867 8.25444 2.14667 8.35178C3.31467 9.52111 6.31467 12.5231 6.31467 12.5231C6.41133 12.6198 6.53867 12.6678 6.666 12.6678C6.79333 12.6671 6.92133 12.6184 7.01933 12.5204C7.21467 12.3251 7.21533 12.0098 7.022 11.8158L3.70467 8.49844H13.4993C13.7753 8.49844 13.9993 8.27444 13.9993 7.99844C13.9993 7.72244 13.7753 7.49844 13.4993 7.49844H3.70467L7.02333 4.17911C7.216 3.98644 7.21467 3.67178 7.01933 3.47644C6.92133 3.37844 6.79333 3.32911 6.666 3.32911C6.53867 3.32844 6.41267 3.37644 6.316 3.47244V3.47244Z" fill="black"/>
              </svg>
              {content.product.back}
             </Link>
            <h1 className=" text-3xl lg:text-5xl font-medium pt-6">{homeContent?.title}</h1>
            <div className="text-[18px] leading-[160%] pt-8 pb-4">{homeContent?.text && parse(homeContent?.text)}</div>
            <div className="pb-6 text-[14px]">
              {homeContent?.tags && homeContent?.tags?.map((item, ind)=>(
                <span key={ind} className="mr-4">{item.name}</span>
              ))}
            </div>
            <a href={`mailto:info@kiosks.ee?subject=${homeContent?.title}`} className=" px-3 py-1 bg-pr rounded-2xl font-medium  hover:bg-srhover">
              {content.product.request}
            </a>
          </div>
          <div className="flex justify-center">
            <img src={homeContent?.image} alt="" className="md:max-h-[none] max-h-[300px]" />
          </div>
        </div>
      </div>
      <div className=" w-full md:px-8 py-8 md:py-32">
        <div className=" container">
          <div className="md:grid grid-cols-1 lg:grid-cols-2 gap-20">
            <div>
              <div
                className=" pt-6 pl-7 text-[18px] leading-[160%] list"
                dangerouslySetInnerHTML={{
                  __html: homeContent?.blocks?.[0]?.text,
                }}
              ></div>
              <div className="pl-7 flex items-center gap-2">
                <a href={`mailto:info@kiosks.ee?subject=${homeContent?.title}`} className=" px-3 py-1 mt-6 bg-pr rounded-2xl font-medium  hover:bg-srhover">
                  {content.product.askformore}
                </a>
                {homeContent?.blocks?.[0]?.datasheet && 
                  <a href={homeContent?.blocks?.[0]?.datasheet} target="_blank" className=" px-3 py-1 mt-6 bg-transparent border flex items-center gap-2 border-pr rounded-2xl font-medium  hover:border-srhover" rel="noreferrer">
                     {content.product.datasheet} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5747 2.28234C4.90936 2.08167 5.33203 2.32434 5.33203 2.71034C5.33203 2.88634 5.24003 3.04967 5.0887 3.13967C3.43803 4.13034 2.33203 5.93767 2.33203 8.00101C2.33203 11.1283 4.8707 13.6663 7.99736 13.6663C11.124 13.6663 13.662 11.1283 13.662 8.00101C13.662 5.93901 12.558 4.13234 10.908 3.14167C10.758 3.05101 10.6654 2.88834 10.6654 2.71234C10.6654 2.32434 11.0887 2.08367 11.4234 2.28434C13.3634 3.44967 14.662 5.57501 14.662 8.00101C14.662 11.6803 11.676 14.6663 7.99736 14.6663C4.3187 14.6663 1.33203 11.6803 1.33203 8.00101C1.33203 5.57367 2.6327 3.44767 4.5747 2.28234V2.28234ZM5.1287 8.00367C5.1287 8.00367 6.4787 9.35101 7.64803 10.5197C7.74603 10.6177 7.87403 10.6663 8.00136 10.6663C8.12936 10.6663 8.25736 10.6177 8.35536 10.5197C9.52403 9.35167 10.8747 8.00301 10.8747 8.00301C10.9714 7.90634 11.0194 7.77901 11.0194 7.65234C11.0194 7.52434 10.97 7.39634 10.872 7.29834C10.6774 7.10301 10.3614 7.10234 10.168 7.29567L8.50136 8.96167V1.83301C8.50136 1.55701 8.27736 1.33301 8.00136 1.33301C7.72536 1.33301 7.50136 1.55701 7.50136 1.83301V8.96167L5.83603 7.29634C5.64336 7.10367 5.32803 7.10567 5.13336 7.30034C5.03536 7.39834 4.98603 7.52634 4.98536 7.65434C4.98536 7.78101 5.0327 7.90767 5.1287 8.00367V8.00367Z" fill="black"/></svg>
                  </a>
                }
              </div>
            </div>
            <div>
              <div
                className=" pt-8 md:pt-6 pl-7 md:pl-9 text-[18px] leading-[160%] list"
                dangerouslySetInnerHTML={{
                  __html: homeContent?.blocks?.[1]?.text,
                }}
              ></div>
           
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-28">
        <div className="container md:px-8 ">
            {homeContent?.blocks?.[2] && 
              <h1 className=" font-medium text-2xl lg:text-4xl pb-6">{content.product.gallery}</h1>
            }
            <div className=" w-full grid grid-cols-1  lg:grid-cols-5 gap-8">
            <Fancybox>
              {homeContent?.blocks?.[2] && homeContent?.blocks?.[2]?.gallery?.map((item, ind)=>(
                  <a key={ind} className="group overflow-hidden rounded cursor-pointer" data-caption={item?.title} data-fancybox="gallery" href={item?.image}>
                    <img src={item?.thumbnail} title={item?.title} alt="" className="object-fill w-full h-full object-contain group-hover:scale-[1.1] transition duration-[600ms] max-w-[300px] md:max-w-[none]" />
                  </a>
              ))}
            </Fancybox>
            </div>
            {/* <SlideshowLightbox className='w-full grid grid-cols-1  lg:grid-cols-5 gap-8' showThumbnails={true}>
              {homeContent?.blocks?.[2] && homeContent?.blocks?.[2]?.gallery?.map((item, ind)=>(
                    <a key={ind} className="group overflow-hidden rounded cursor-pointer" data-caption={item?.title} data-fancybox="gallery" href={item?.image}>
                      <img key={ind} src={item?.thumbnail} title={item?.title} alt="" className="object-fill w-full h-full object-contain group-hover:scale-[1.1] transition duration-[600ms] max-w-[300px] md:max-w-[none]" />
                    // </a>
                ))}
            </SlideshowLightbox>  */}
            
        </div>
      </div>
      {homeContent?.related_kiosks && 
      <div className="w-full pb-28">
        <div className="container md:px-8 ">

            <h1 className=" font-medium text-2xl lg:text-4xl pb-6">{content.product.related}</h1>
            <div className="container">
              <div className=" w-full pt-4 grid grid-cols-1 lg:grid-cols-3 gap-1">
                {homeContent?.related_kiosks?.map((item, ind) => (
                  <Link key={ind} to={`/product/${item?.slug}`} className=" relative w-full h-56 cursor-pointer rounded_px group overflow-hidden bg-[#e3ddd9]">
                    <img src={item?.image} className=" h-full pt-3 w-full object-contain group-hover:scale-[1.1] transition duration-[600ms]" alt="" />
                    <div className=" absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full" style={{background:"rgba(255,255,255, 0.5)"}}>
                      <h1 className=" text-2xl text-center font-semibold">{item?.title}</h1>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            {homeContent?.blocks?.[2] && 
            <div className=" flex items-center gap-2 justify-center pt-20">
                <a href={`mailto:info@kiosks.ee?subject=${homeContent?.title}`} className=" px-3 py-1 mt-6 bg-pr rounded-2xl font-medium  hover:bg-srhover">
                {content.product.askformore}
                </a>
               
              </div>
            }
        </div>
      </div>
      }
    </div>
    </>
  );
};

export default Product;
